import React from "react";
import { Row, Col, Tag, Typography, Card } from "antd";
import moment from "moment";

const { Title, Paragraph, Text } = Typography;

export default function ViewTask({data}) {
  return (
    <>
      <Title level={5} className="!my-5">Task Heading</Title>
      <Row gutter={[12, 24]}>
        <Col span={24} md={8}>
          <p className="text-gray-400 mb-2">
            Task ID : <span className="text-black">{data?.id}</span>
          </p>
          <p className="text-gray-400 mb-2">
            Amount : <span className="text-black">{data?.completionAmount}</span>
          </p>
          <p className="text-gray-400 mb-2">
            URL :  
            <a 
              href={data?.helpLink} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-primary"
            >
              {" " + data?.helpLink}
            </a>
        </p>
        <p className="text-gray-400 mb-2">
            Track Url :
            <a 
              href={data?.trackUrl} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-primary"
            >
              {" " + data?.trackUrl}
            </a>
        </p>
          <p className="text-gray-400 mb-2">
            Is Region Base : <span className="text-black">{data?.isRegionBased ? 'Yes' : 'No'}</span>
          </p>
        </Col>
        <Col span={24} md={8}>
          <p className="text-gray-400 mb-2">
            Selected Brand : <span className="text-black">{data?.brand?.name}</span>
          </p>
          <p className="text-gray-400 mb-2">
            No. of Spin : <span className="text-black">{data?.completionSpin}</span>
          </p>
          <p className="text-gray-400 mb-2">
            Task Priority : <span className="text-black">{data?.priority}</span>
          </p>
          <p className="text-gray-400 mb-2">
            End Goal : <span className="text-black">{data?.endGoal}</span>
          </p>
          <p className="text-gray-400 mb-2">
          Default Number Of Days For Resubmit On Reject : <span className="text-black">{data?.defaultNumberOfDaysForResubmitOnReject}</span>
          </p>
          <p className="text-gray-400 mb-2">
          Default Message On Reject : <span className="text-black">{data?.defaultAdminMessageOnReject}</span>
          </p>
        </Col>
        <Col span={24} md={8}>
          <p className="text-gray-400 mb-2">
            Task Category : <span className="text-black">{data?.taskCategory?.name}</span>
          </p>
          <p className="text-gray-400 mb-2">
            Expiry Date : <span className="text-black">{moment(data?.expireDate).format('DD/MM/YYYY')}{}</span>
          </p>
          <p className="text-gray-400 mb-2">
            Completion Time : <span className="text-black">{data?.completionTime}</span>
          </p>
          <p className="text-gray-400 mb-2">
          Review Days : <span className="text-black">{data?.reviewDays}</span>
          </p>
          <p className="text-gray-400 mb-2">
          Extend Review Days : <span className="text-black">{data?.extendReviewDays}</span>
          </p>
        </Col>
      </Row>
      <Row>
        <div className="flex gap-2">
            {data?.isRegionBased && data?.regions?.split(',')?.map((region, index) => (
              <span className="py-2 px-3 bg-[#DBECFF80] rounded-3xl">{region}</span>
            ))}
        </div>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={5}>Descriptions</Title>
          <Card className="max-h-52 overflow-y-auto py-1 px-1">
            <div dangerouslySetInnerHTML={{ __html: data?.description }} />
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={5}>Task Terms & Condition</Title>
          <Card className="max-h-52 overflow-y-auto py-1 px-1">
            <div dangerouslySetInnerHTML={{ __html: data?.termAndCondition }} />
          </Card>
        </Col>
      </Row>
      {data?.trainingUrl && (<Row gutter={[16, 16]} style={{ marginTop: 20 }}>
        <Col>
        <Title level={5}>Training URL with banner</Title>
        <Card
          className="h-[120px] rounded-lg overflow-hidden shadow-lg border border-gray-300"
          cover={
            <img
              src={data?.banner}
              className="p-2"
              height={120}
            />
          }
          >
        </Card>
        </Col>
        <Col className="flex items-center pt-6">
        <p className="text-gray-400 mb-2">
            URL :  
            <a 
              href={data?.trainingUrl} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-primary"
            >
              {" " + data?.trainingUrl}
            </a>
        </p>
        </Col>
      </Row>)}
    </>
  );
}
