import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FreeMode, Keyboard, Navigation, Pagination, Thumbs } from "swiper";
import { Image } from "antd";
import { FaCloudDownloadAlt } from "react-icons/fa";

export default function WorkProof({ MultipleImages = [] }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const isImageUrl = (url) => {
    return /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i.test(url);
  };
  return (
    <div className="customab h-full w-full">
      <Swiper
        style={{
          "--swiper-navigation-color": "#000",
          "--swiper-pagination-color": "#000",
        }}
        slidesPerView={1}
        slidesPerGroup={1}
        spaceBetween={10}
        navigation
        loop={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs, Pagination]}
        className={`!h-96`}
      >
        {MultipleImages?.map((image, index) => (
          <SwiperSlide key={index} className="rounded-sandee">
            {isImageUrl(image) ? (
              <Image
                className="rounded-sandee transition-transform duration-1000 ease-in-out transform group-hover:scale-125 !h-80"
                src={image}
                preview={false}
                alt={`Displayed image ${index + 1}`}
              />
            ) : (
              <div className="flex flex-col">
                <div>File type is {image?.split(".")?.reverse()[0]}</div>
                <a className="flex gap-2 items-center" href={image}>
                  Download <FaCloudDownloadAlt size={20} />
                </a>
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      {MultipleImages?.length > 1 && (
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={15}
          slidesPerView={7}
          loop={true}
          modules={[FreeMode, Navigation, Thumbs, Keyboard]}
          className="mySwiper"
        >
          {MultipleImages?.map((el, index) => (
            <SwiperSlide
              key={index}
              className="overflow-hidden rounded-md !w-[100px] !h-[75px] !md:w-[90px] !lg:w-[90px] !xl:w-[100px] !mx-2 !p-1 border-2"
            >
              {isImageUrl(el) ? (
                <Image
                  className=" rounded-md transition-transform duration-1000 ease-in-out transform group-hover:scale-125 w-full"
                  src={el}
                  preview={false}
                />
              ) : (
                <div className="flex gap-2 items-center" >
                  <FaCloudDownloadAlt size={30} color="#1677ff"/>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}
