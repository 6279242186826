import {
  Button,
  Card,
  Modal,
  Radio,
  Row,
  Select,
  DatePicker,
  Tabs,
  notification,
  Popconfirm,
  Progress,
  message,
} from "antd";
import React, { memo, useEffect, useMemo, useState } from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import CRUDComponent from "../../../component/common/CRUD-Component";
import Heading from "../../../component/common/Heading";
import { PlusCircleOutlined } from "@ant-design/icons";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { EditOutlined } from "@ant-design/icons";
import {
  PERMISSION,
  TASK_APPROVAL_FORMS,
  TASK_APPROVAL_OPTION,
  apiGenerator,
  convertUTCToLocal,
} from "../../../util/functions";
import ViewTask from "../../../component/taskApproval/ViewTask";
import WorkProof from "../../../component/taskApproval/WorkProof";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import {
  taskApprovalExporAlltData,
  taskApprovalExportData,
} from "../../../util/exportData";
import BulkAction from "./BulkAction";
import dayjs from "dayjs";
import Services from "../../../util/API/service";

const TaskApproval = ({ admindetails, permissionId, isAdmin = false }) => {
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];
  const api = useHttp();
  const { id } = useParams();
  const [statusTask, setStatusTask] = useState([]);
  const [openModal, setOpenModal] = useState(null);
  const [selectedDates, setSelectedDates] = useState({});
  const [modalData, setModalData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [taskList, setTaskList] = useState(null);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [activeForm, setActiveForm] = useState(null);
  const [taskStatus, setTaskStatus] = useState(null);
  const [reload, setRealod] = useState(false);
  const [bulkAction, setBulkAction] = useState(false);
  const [exportDataFields, setExportDataFields] = useState([]);

  const detailsModal = {
    taskDetails: {
      title: "Task Details",
      child: <ViewTask data={modalData} />,
      width: 900,
    },
    workProof: {
      title: "Proof of Work",
      child: <WorkProof MultipleImages={modalData} />,
      width: 800,
    },
  };

  useEffect(() => {
    api.sendRequest(
      CONSTANTS.API.Task_Management.getAll,
      (res) => {
        const option = res?.data?.rows?.map((task) => {
          return { Label: task?.name, value: task?.id, id: task?.id };
        });
        setTaskList(option);
      }
      // { status: "Active" }
    );
  }, []);

  const onChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedDates({
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      });
    } else {
      setSelectedDates({});
    }
  };

  const OnFormSubmitBtn = (res) => {
    const payload = { ...res };
    payload["status"] = activeForm?.form;
    api.sendRequest(
      apiGenerator(CONSTANTS.API.UserTask.updateModal, {
        dataId: activeForm?.id,
      }),
      (res) => {
        activeForm?.setRefresh((pr) => !pr);
        setActiveForm(null);
      },
      payload,
      "Data updated successfully"
    );
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const memoizedExtraQuery = useMemo(() => {
    return {
      ...(taskStatus && { status: taskStatus }),
      ...(id && { taskId: id }),
      ...(selectedTaskId && { taskId: selectedTaskId }),
      ...(selectedDates?.startDate && selectedDates),
    };
  }, [taskStatus, id, selectedDates, selectedTaskId]);

  const taskApprovalExport = (data) => {
    const maxLength = Math.max(
      ...data?.map((item) => (item.proofOfWork ? item.proofOfWork.length : 0))
    );
    const proofOfWorkFiels = maxLength
      ? Array.from({ length: maxLength }, (_, i) => [
          `link${i + 1}`,
          `Link ${i + 1}`,
        ])
      : [];

    setExportDataFields([...taskApprovalExportData, ...proofOfWorkFiels]);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const approveAll = () => {
    if (!selectedTaskId && !id) {
      message.error({ content: "Please Select Task" });
      return;
    }
    const status = `?status=Pending&taskId=${selectedTaskId ?? id}`;
    setProgress(0);
    api.sendRequest(
      apiGenerator(CONSTANTS.API.UserTask.getAll, {}, status),
      (res) => {
        api.sendRequest(
          apiGenerator(
            CONSTANTS.API.UserTask.getAll,
            {},
            status + "&limit=" + res.data?.count
          ),
          async (res) => {
            let count = 0;
            for (const element of res.data.rows) {
              let payload = {
                rating: 5,
                status: "Accepted",
              };
              if (element.status !== payload.status) {
                try {
                  await delay(500);
                  const response = await Services.patch(
                    CONSTANTS.API.UserTask.updateModal.endpoint.replace(
                      ":dataId",
                      element.id
                    ),
                    payload
                  );
                  console.log(response.status);
                } catch (error) {
                  console.log(error);
                }
              }
              count++;
              setProgress((count / res.data?.count) * 100);
            }
            setProgress(null);
            setRealod((pre) => !pre);
          }
        );
      }
    );
  };

  useEffect(() => {
    api.sendRequest(
      CONSTANTS.API.UserTask.taskCount,
      (res) => {
        const taskSubmissions = res?.data?.reduce((acc, item) => {
          acc[item.status] = item.count;
          acc.total = (acc.total || 0) + item.count;
          return acc;
        }, {});
        setStatusTask([
          {
            label: `All (${taskSubmissions?.total || 0})`,
            value: null,
          },
          {
            label: `In-Progress (${taskSubmissions?.InProgress || 0})`,
            value: "InProgress",
          },
          {
            label: `Pending (${taskSubmissions?.Pending || 0})`,
            value: "Pending",
          },
          {
            label: `Resubmit (${taskSubmissions?.Resubmit || 0})`,
            value: "Resubmit",
          },
          {
            label: `Accepted (${taskSubmissions?.Accepted || 0})`,
            value: "Accepted",
          },
          {
            label: `Rework (${taskSubmissions?.Rework || 0})`,
            value: "Rework",
          },
          {
            label: `Reject (${taskSubmissions?.Reject || 0})`,
            value: "Reject",
          },
        ]);
      },
      {
        ...selectedDates,
        ...(id && { taskId: id }),
        ...(selectedTaskId && { taskId: selectedTaskId }),
      }
    );
  }, [selectedDates, selectedTaskId, id]);

  return (
    <>
      <div>
        {progress ? (
          <Progress percent={progress} size="small" />
        ) : (
          ""
        )}
        <Card className="my-5">
          <Heading>Task Approval List</Heading>
          <Row className="flex justify-between items-baseline">
            {statusTask?.length && (
              <Radio.Group
                value={taskStatus}
                onChange={(e) => setTaskStatus(e.target.value)}
                className="mt-6"
              >
                {statusTask?.map((st) => (
                  <Radio.Button value={st?.value}>{st?.label}</Radio.Button>
                ))}
              </Radio.Group>
            )}
            <div className="flex gap-2">
              {isAdmin && id && (
                <div className="flex gap-3">
                  <a
                    href="/bulk-action.csv"
                    className="flex gap-1 text-sm items-end"
                  >
                    Sample file <FaCloudDownloadAlt size={20} />
                  </a>
                  <Button
                    type="primary"
                    className="flex"
                    icon={<PlusCircleOutlined className="text-xl" />}
                    onClick={() => setBulkAction((pre) => !pre)}
                  >
                    Bulk Action
                  </Button>
                </div>
              )}
              <div className="flex">
                {!id && (
                  <Select
                    showSearch
                    placeholder={"Select Task"}
                    className="w-48 me-3"
                    allowClear
                    onChange={(value) => setSelectedTaskId(value)}
                  >
                    {taskList?.map((item) => (
                      <Select.Option key={`role_${item.id}`} value={item.value}>
                        {item.Label ? item.Label : item.value}
                      </Select.Option>
                    ))}
                  </Select>
                )}
                {admindetails?.role === "Admin" && (
                  <Popconfirm
                    onConfirm={() => approveAll()}
                    title={"Are you sure"}
                  >
                    <Button type="primary" className="me-3">
                      Approve All Pending
                    </Button>
                  </Popconfirm>
                )}
                <DatePicker.RangePicker
                  className={`${
                    selectedDates?.startDate && selectedDates?.endDate
                      ? "w-[15rem]"
                      : "w-[2.5rem] hide-arrow !ps-2"
                  }`}
                  allowClear={true}
                  value={
                    selectedDates?.startDate && selectedDates?.endDate
                      ? [
                          dayjs(selectedDates.startDate),
                          dayjs(selectedDates.endDate),
                        ]
                      : []
                  }
                  onChange={onChange}
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                />
              </div>
            </div>
          </Row>
          <Row>
            <CRUDComponent
              reload={reload}
              title="TaskApproval"
              exportData={admindetails?.role === "Admin" && exportDataFields}
              exportAllData={
                admindetails?.role === "Admin" && taskApprovalExporAlltData
              }
              // accessPermission={accessPermission}
              GET={{
                API: CONSTANTS.API.UserTask.getAll,
                extraQuery: memoizedExtraQuery,
                DataModifier: (res, API, Setrefresh, extraData) => {
                  taskApprovalExport(res);
                  return res?.map((data) => {
                    return {
                      ...data,
                      no: data?.id,
                      image: {
                        image: data?.user?.image,
                        name: data?.user?.name,
                      },
                      action: data?.status,
                      name: data?.user?.name,
                      mobile: data?.user?.mobile,
                      taskId: data?.taskId,
                      taskName: data?.task?.name,
                      taskDetails: {
                        id: "taskDetails",
                        onClick: (id) => {
                          if (!data?.taskId) return;
                          const GET_TASK_API = apiGenerator(
                            CONSTANTS.API.Task_Management.getOne,
                            {
                              id: data?.taskId,
                            }
                          );
                          api.sendRequest(GET_TASK_API, (res) => {
                            if (!res) return;
                            setModalData(res?.data);
                            setOpenModal(id);
                          });
                        },
                      },
                      ...data?.proofOfWork?.reduce((acc, link, index) => {
                        acc[`link${index + 1}`] = link;
                        return acc;
                      }, {}),
                      proofOfWorkList: data?.proofOfWork?.join("\n"),
                      proofOfWork: {
                        id: "workProof",
                        onClick: (id) => {
                          if (!data?.proofOfWork?.length)
                            return notification.error({
                              message: "Data not found",
                            });
                          setModalData(data?.proofOfWork);
                          setOpenModal(id);
                        },
                      },
                      taskSubmissionDateTime: convertUTCToLocal(
                        data?.submissionDateTime
                      ),
                      status: (
                        <Select
                          className="w-28"
                          placeholder={"Select"}
                          disabled={PERMISSION[accessPermission] === "READ"}
                          value={
                            ["Accepted", "Reject", "Rework"].includes(
                              data?.status
                            )
                              ? data?.status
                              : null
                          }
                          onClear={() => {
                            setActiveForm(null);
                          }}
                          onChange={(value, f) => {
                            setActiveForm({
                              id: data?.id,
                              form: value,
                              setRefresh: Setrefresh,
                            });
                            console.log(value);
                          }}
                        >
                          {TASK_APPROVAL_OPTION.map((item) => (
                            <Select.Option key={`${item.value}`}>
                              {item.label ? item.label : item.value}
                            </Select.Option>
                          ))}
                        </Select>
                      ),
                    };
                  });
                },
                column: CONSTANTS.TABLE.TASKAPPROVAL,
              }}
              isSearch
              UPDATE={{
                API: CONSTANTS.API.UserTask.update,
                message: "Message Updated successfully",
                modaltitle: "Message",
                modalFields: CONSTANTS?.FORM_FIELD?.TASK_APPROVAL_MESSAGE,
                // payloadModifier: (res) => res,
              }}
            />
          </Row>
        </Card>
      </div>
      <Modal
        title={detailsModal[openModal]?.title}
        centered
        open={openModal}
        onCancel={() => {
          setOpenModal(null);
          setModalData(null);
        }}
        width={detailsModal[openModal]?.width}
        footer={null}
      >
        {openModal && detailsModal[openModal]?.child}
      </Modal>
      <ModalFormCreator
        loading={api.isLoading}
        open={activeForm}
        onCreate={OnFormSubmitBtn}
        onCancel={() => {
          setActiveForm((pre) => !pre);
        }}
        menuFields={
          CONSTANTS.FORM_FIELD[TASK_APPROVAL_FORMS[activeForm?.form]?.form]
        }
        name={TASK_APPROVAL_FORMS[activeForm?.form]?.modaltitle}
        SubmitName={"Submit"}
      />
      {id && (
        <BulkAction
          bulkAction={bulkAction}
          setBulkAction={setBulkAction}
          api={api}
          id={id}
        />
      )}
    </>
  );
};

export default memo(TaskApproval);
